import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

interface IAboutText {
  text: string;
  email: string;
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  public readonly aboutText$: Observable<IAboutText> = this._httpClient.get(
    './assets/about.json',
    {
      responseType: 'json',
    }
  ) as Observable<IAboutText>;

  constructor(
    private _httpClient: HttpClient,
    private _dialog: MatDialogRef<AboutComponent>
  ) {}

  ngOnInit(): void {}

  close() {
    this._dialog?.close();
  }
}
