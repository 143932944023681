import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BedService } from 'src/app/services/bed.service';
import { IHospitalData } from '../../data/hospital-data';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const comunidades = [
  {
    comunidad: 'Andalucía',
    provincias: [
      'Almería',
      'Cádiz',
      'Córdoba',
      'Granada',
      'Huelva',
      'Jaén',
      'Málaga',
      'Sevilla',
    ],
  },
  { comunidad: 'Aragón', provincias: ['Huesca', 'Teruel', 'Zaragoza'] },
  { comunidad: 'Asturias, Principado de', provincias: ['Asturias'] },
  { comunidad: 'Balears, Illes', provincias: ['Balears, Illes'] },
  {
    comunidad: 'Canarias',
    provincias: ['Palmas, Las', 'Santa Cruz de Tenerife'],
  },
  { comunidad: 'Cantabria', provincias: ['Cantabria'] },
  {
    comunidad: 'Castilla y León',
    provincias: [
      'Ávila',
      'Burgos',
      'León',
      'Palencia',
      'Salamanca',
      'Segovia',
      'Soria',
      'Valladolid',
      'Zamora',
    ],
  },
  {
    comunidad: 'Castilla-La Mancha',
    provincias: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara', 'Toledo'],
  },
  {
    comunidad: 'Cataluña',
    provincias: ['Barcelona', 'Girona', 'Lleida', 'Tarragona'],
  },
  {
    comunidad: 'Comunitat Valenciana',
    provincias: ['Alicante/Alacant', 'Castellón/Castelló', 'Valencia/València'],
  },
  { comunidad: 'Extremadura', provincias: ['Badajoz', 'Cáceres'] },
  {
    comunidad: 'Galicia',
    provincias: ['Coruña, A', 'Lugo', 'Ourense', 'Pontevedra'],
  },
  { comunidad: 'Madrid, Comunidad de', provincias: ['Madrid'] },
  { comunidad: 'Murcia, Región de', provincias: ['Murcia'] },
  { comunidad: 'Navarra, Comunidad Foral de', provincias: ['Navarra'] },
  {
    comunidad: 'País Vasco',
    provincias: ['Araba/Álava', 'Bizkaia', 'Gipuzkoa'],
  },
  { comunidad: 'Rioja, La', provincias: ['Rioja, La'] },
  { comunidad: 'Ceuta', provincias: ['Ceuta'] },
  { comunidad: 'Melilla', provincias: ['Melilla'] },
];

@Component({
  selector: 'app-hospital-editor',
  templateUrl: './hospital-editor.component.html',
  styleUrls: ['./hospital-editor.component.scss'],
})
export class HospitalEditorComponent implements OnInit, OnDestroy {
  form: FormGroup;

  public readonly disclaimerText$ = this._httpClient.get(
    './assets/disclaimer.txt',
    { responseType: 'text' }
  );

  public readonly comunidades = comunidades;

  private _hospitalData: IHospitalData;
  private _sub: Subscription;

  constructor(
    private _bedService: BedService,
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    const id = this._activatedRoute.snapshot.paramMap.get('id');
    this._sub = this._bedService.getHospital(id).subscribe(
      (hospital) => {
        this._hospitalData = hospital;
        this.buildForm();
      },
      (error) => {
        this.buildForm();
      }
    );
  }

  buildForm() {
    this.form = new FormGroup({
      hospitalID: new FormControl(this._hospitalData?.hospital.name, [
        Validators.required,
      ]),
      available: new FormControl(this._hospitalData?.beds.available ?? 0, [
        Validators.required,
      ]),
      total: new FormControl(this._hospitalData?.beds.available_3d ?? 0, [
        Validators.required,
      ]),
      contact: new FormControl(this._hospitalData?.contact.email, [
        Validators.required,
      ]),
      location: new FormControl(
        this._hospitalData?.hospital.location?.address || ''
      ),
      province: new FormControl(
        this._hospitalData?.hospital.location?.province || ''
      ),
      phone: new FormControl(this._hospitalData?.contact.phone || ''),
      comments: new FormControl(this._hospitalData?.contact.comments),
      name: new FormControl(this._hospitalData?.contact.name),
    });
  }

  submit() {
    const data: IHospitalData = {
      ...this._hospitalData,
      hospital: {
        ...this._hospitalData?.hospital,
        name: this.form.value.hospitalID,
        location: {
          ...this._hospitalData?.hospital.location,
          address: this.form.value.location,
          province: this.form.value.province,
        },
      },
      beds: {
        ...this._hospitalData?.beds,
        available: this.form.value.available,
        available_3d: this.form.value.total,
      },
      contact: {
        name: this.form.value.name,
        email: this.form.value.contact,
        phone: this.form.value.phone,
        comments: this.form.value.comments,
      },
      updated: new Date(),
    };
    this._bedService.updateHospital(data).subscribe((_) => {
      this._snackBar.open('Saved', null, { duration: 800 });
      this._location.back();
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
