import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenResolver implements Resolve<string> {
  constructor() {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {
    const fragment = route.fragment;
    const reg = new RegExp(/(id_token=)[\w\s\.\-]+/, 'i');
    const token = reg.exec(fragment)?.[0]?.replace('id_token=', '');

    if (!!token?.length) {
      return token;
    } else {
      return undefined;
    }
  }

}
