import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public readonly loginUrl = environment.api.login;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const newToken = this._route.snapshot.data?.token;

    if (!!newToken){
      this._auth.setToken(newToken);
      this._auth.createSession(newToken);
    } else {
      this._reuseSession(this._auth.getToken());
    }
  }

  private async _reuseSession(token: string) {
    await this._auth.reuseSession(token);
  }
}
