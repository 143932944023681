<mat-sidenav-container class="example-container">
  <mat-sidenav
    mode="over"
    #sidenav="matSidenav"
    [opened]="false"
    style="width: 50vw; max-width: 300px;"
  >
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-button (click)="sidenav.close()">Menu</button>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="flex-column side-menu">
      <a
        mat-flat-button
        color="primary"
        class="full-width"
        (click)="sidenav.close()"
        [routerLink]="['/beds/edit/new']"
      >
        <mat-icon>add</mat-icon>
        Añadir hospital</a
      >
      <a
        mat-flat-button
        color="white"
        class="full-width"
        style="margin-top: 10px;"
        [href]="supportLink"
      >
        <mat-icon>help</mat-icon>
        Soporte</a
      >
      <button
        mat-flat-button
        color="white"
        class="full-width"
        style="margin-top: 10px;"
        (click)="showAbout()"
      >
        <mat-icon>info</mat-icon>
        Información
      </button>
      <button
        mat-button
        color="warn"
        class="full-width"
        (click)="logout(); sidenav.close()"
        [routerLink]="['login']"
        style="margin-top: 40px;"
      >
        Cerrar sesión
      </button>
      <div style="margin-top: auto; text-align: center; color: gray;">
        v. {{ version }}
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row
        *ngIf="{ user: user$ | async } as state"
        style="
          flex-direction: row;
          display: flex;
          justify-content: space-between;
        "
      >
        <ng-container *ngIf="state.user">
          <button
            mat-button
            style="
              flex: 1 1 33%;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
            (click)="sidenav.toggle()"
          >
            <mat-icon color="white" style="margin-right: 6px;">menu</mat-icon>
            <a style="text-decoration: none; color: white;">
              {{ state.user?.email || "Login" }}
            </a>
          </button>
          <button
            *ngIf="false"
            mat-button
            (click)="refresh()"
            style="
              flex: 1 1 33%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            "
          >
            <span>{{ "Actualizar" | uppercase }}</span>
            <mat-icon style="margin-left: 6px;">refresh</mat-icon>
          </button>
        </ng-container>
        <button
          mat-icon-button
          color="white"
          *ngIf="state.user"
          (click)="refresh()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="main-layout">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
