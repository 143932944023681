import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

interface IData {
  hospitalName?: string;
  updated?: Date;
  username?: string;
  phone?: string;
  email?: string;
}

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit {
  public data;
  constructor(
    @Optional()
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public bottomSheetData: IData,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public dialogdata: IData
  ) {
    this.data = dialogdata || bottomSheetData;
  }

  ngOnInit(): void {}
}
