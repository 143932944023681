import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { IContact } from '../data/contact';
import { map, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _http: HttpClient, private router: Router) {}

  private _token = new BehaviorSubject<string>(undefined);
  currentUser$: Observable<IContact> = this._token.asObservable().pipe(
    switchMap((token) => {
      return this._tokenHasValue(token)
        ? this._http.get(`${environment.api.url}sessions`)
        : of(undefined);
    }),
    catchError((_) => of(undefined)),
    map((userData: { username: string }) => {
      return userData
        ? {
            email: userData.username,
          }
        : undefined;
    })
  );
  isAuthenticated$: Observable<boolean> = this.currentUser$.pipe(
    map((user) => !!user)
  );

  setToken(token: string): void {
    localStorage.setItem('sess_token', token);
  }

  getToken(): string {
    return localStorage.getItem('sess_token');
  }

  async createSession(newToken: string){
    this._http.post(`${environment.api.url}sessions`, {}).subscribe(
      _ => {
        this._token.next(newToken);
        this.router.navigateByUrl('/beds');
      }
    );
  }

  async reuseSession(token: string): Promise<void> {
    if (this._tokenHasValue(token)){
      this._token.next(token);
      this.router.navigateByUrl('/beds');
    }
  }

  logout() {
    localStorage.removeItem('sess_token');
    this._token.next(undefined);
  }

  private _tokenHasValue(token: string){
    return token && token !== 'undefined' && token !== 'null';
  }
}
