<div class="about">
  <div class="about-logos">
    <a href="https://www.msf.es" target="_blank"
      ><img src="assets/MSF_logo.jpg" alt="MSF logo"
    /></a>
    <a href="https://www.spotlab.org" target="_blank"
      ><img src="assets/spotlab_logo_about.png" alt="spotlab logo"
    /></a>
  </div>
  <ng-container *ngIf="aboutText$ | async as info">
    <div class="about-text">
      {{ info.text }}
      <p *ngIf="info.email">
        Contacto: <a href="mailto:{{ info.email }}">{{ info.email }}</a>
      </p>
    </div>
  </ng-container>
  <button
    class="about-close-button"
    mat-button
    color="primary"
    (click)="close()"
  >
    Cerrar
  </button>
</div>
