<table
  mat-table
  matSort
  [dataSource]="dataSource"
  class="mat-elevation-z8 custom-table"
>
  <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="map">
    <th mat-header-cell *matHeaderCellDef>
      Ubicación
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <a
        *ngIf="element.hospital.location.address?.length"
        href="https://www.google.com/maps/search/{{
          element.hospital.location.address
        }}"
        style="color: black; margin-right: 10px;"
      >
        <mat-icon>place</mat-icon>
      </a>
    </td>
  </ng-container>

  <!-- hospital Column -->
  <ng-container matColumnDef="hospital" sticky>
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Hospital
    </th>
    <td mat-cell *matCellDef="let element">
      <div style="flex-direction: column; display: flex;">
        <div>
          {{ element.hospital.name }}
        </div>
        <div class="last-connection">
          {{ element.updated | date: "short" }}
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Available Column -->
  <ng-container matColumnDef="available">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      UCI disponibles
    </th>
    <td mat-cell *matCellDef="let element">
      <div
        style="text-align: center; font-weight: bolder;"
        [class.text-danger]="element.beds.available < 1"
      >
        {{ element.beds.available }}
      </div>
    </td>
  </ng-container>

  <!-- total_beds Column -->
  <ng-container matColumnDef="total_beds">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>UCI totales</th>
    <td mat-cell *matCellDef="let element">
      <div style="text-align: center; font-weight: bolder;">
        {{ element.beds.available_3d }}
      </div>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="contact">
    <th mat-header-cell *matHeaderCellDef style="padding: auto;">
      Contacto
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
        "
      >
        <button
          mat-icon-button
          (click)="openContactDialog(element)"
          style="color: gray;"
        >
          <mat-icon color="accent">email</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <!-- Province Column -->
  <ng-container matColumnDef="province">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Provincia
    </th>
    <td mat-cell *matCellDef="let element">
      <a
        mat-button
        style="display: flex; flex-direction: row; align-items: center;"
        href="https://www.google.com/maps/search/{{
          element.hospital.location.address ||
            element.hospital.name + ', ' + element.hospital.location.province
        }}"
      >
        <mat-icon>
          <ng-container *ngIf="element.hospital.location?.province">
            place
          </ng-container>
        </mat-icon>

        <span>{{ element.hospital.location.province }}</span>
      </a>
    </td>
  </ng-container>

  <!-- Actions column -->
  <ng-container matColumnDef="actions" sticky>
    <th mat-header-cell *matHeaderCellDef>
      <!-- <button mat-icon-button [routerLink]="['edit', 'new']">
        <mat-icon>add</mat-icon>
      </button> -->
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <button
        mat-icon-button
        color="primary"
        [routerLink]="['edit', element.hospital.name]"
        *ngIf="element.editable"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<button
  *ngIf="false"
  mat-fab
  (click)="refresh()"
  style="position: fixed; bottom: 30px; right: 30px; z-index: 2;"
>
  <mat-icon>refresh</mat-icon>
</button>
