import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      map((authenticated) => {
        if (!authenticated) {
          this.router.navigateByUrl(`/login`);
          return false;
        }
        return true;
      })
    );
  }
}
