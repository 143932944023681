import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { IHospitalData } from '../data/hospital-data';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IHospitalPayload } from '../data/hospital-payload';

@Injectable({
  providedIn: 'root',
})
export class BedService {
  private _refreshB$ = new BehaviorSubject<undefined>(undefined);

  public readonly hospitals$: Observable<
    IHospitalData[]
  > = this._refreshB$.asObservable().pipe(
    switchMap((_) =>
      this._http.get(`${environment.api.url}hospitals`).pipe(
        map((res: { Items: Array<IHospitalPayload> }) => {
          return res.Items.map((item) => ({
            hospital: {
              id: item.id,
              name: item.HospitalId,
              location: {
                lat: Number(item.LatLon?.split(/\s/)?.[0]),
                long: Number(item.LatLon?.split(/\s/)?.[1]),
                address: item.Address,
                province: item.Province,
              },
            },
            beds: {
              available: item.NumBedsFree,
              available_3d: item.EstimatedBedsIn3days,
            },
            contact: {
              name: item.ContactName,
              email: item.ContactEmail,
              phone: item.ContactPhone,
              comments: item.ContactDescription,
            },
            updated: item.UpdatedTime,
            editable: item.UsersAllowed,
          }));
        })
      )
    ),
    share()
  );

  constructor(private _http: HttpClient) {}

  public refresh(): void {
    this._refreshB$.next(undefined);
  }

  public updateHospital(data: IHospitalData): Observable<any> {
    if (!!data.hospital.id) {
      return this._http.patch(`${environment.api.url}hospitals`, {
        id: data.hospital.id,
        hospitalId: data.hospital.name,
        numFreeBeds: data.beds.available,
        contactEmail: data.contact.email,
        contactName: data.contact.name,
        contactPhone: data.contact.phone,
        contactDescription: data.contact.comments,
        numBedsEstimatedIn3days: data.beds.available_3d,
        latlon: '',
        address: data.hospital.location?.address,
        province: data.hospital.location.province,
      });
    } else {
      return this._http.post(`${environment.api.url}hospitals`, {
        hospitalId: data.hospital.name,
        numFreeBeds: data.beds.available,
        contactEmail: data.contact.email,
        contactName: data.contact.name,
        contactPhone: data.contact.phone,
        contactDescription: data.contact.comments,
        numBedsEstimatedIn3days: data.beds.available_3d,
        latlon: '',
        address: data.hospital.location?.address,
        province: data.hospital.location.province,
      });
    }
  }

  public getHospital(id: string): Observable<IHospitalData> {
    this.refresh();
    return this.hospitals$.pipe(
      map((hospitals) => hospitals.find((h) => h.hospital.name === id))
    );
  }
}
