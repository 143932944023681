<h3 *ngIf="data.hospitalName" class="hospital-name">
  {{ data.hospitalName | uppercase }}
</h3>
<h4 style="margin-bottom: 0px;">
  {{ "Datos de contacto" | uppercase }}
</h4>
<div style="font-size: smaller; margin-bottom: 16px;">
  Última actualización: {{ data.updated | date: "long" }}
</div>
<div *ngIf="data.username">
  Persona de contacto: <b>{{ data.username || "desconocido" | uppercase }}</b>
</div>

<a
  class="full-width"
  mat-raised-button
  [color]="data.phone ? 'accent' : 'secondary'"
  href="tel:{{ data.phone }}"
  [disabled]="!data.phone"
>
  <mat-icon>phone</mat-icon>
  {{ data.phone || "Sin número" }}
</a>
<a
  class="full-width"
  mat-raised-button
  [color]="data.email ? 'accent' : 'secondary'"
  href="mailto:{{ data.email }}"
  [disabled]="!data.email"
>
  <mat-icon>mail</mat-icon>
  {{ data.email || "Sin e-mail" }}
</a>
<h4>Más información:</h4>
<p class="comments" *ngIf="data.other">{{ data.other }}</p>
