<div
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 100vw;
    background-color: #f5f5f5;
  "
>
  <img src="./assets/UCI_logos.png" alt="" class="logo" />
  <a mat-raised-button color="primary" [href]="loginUrl">Login</a>
</div>
