import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { BedsComponent } from './components/beds/beds.component';
import { HospitalEditorComponent } from './components/hospital-editor/hospital-editor.component';
import { AuthGuardService } from './guards/auth.guard';
import { TokenResolver } from './resolvers/token.resolver';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      token: TokenResolver,
    },
  },
  {
    path: 'beds',
    children: [
      { path: '', component: BedsComponent },
      { path: 'edit/new', component: HospitalEditorComponent },
      { path: 'edit/:id', component: HospitalEditorComponent },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    resolve: {
      token: TokenResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
