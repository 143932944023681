<mat-card style="margin-bottom: 30px;" *ngIf="form; else loading">
  <mat-card-header>
    <mat-card-title style="text-align: center;">
      Datos del hospital
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" (submit)="submit()">
      <h4>Hospital</h4>
      <mat-form-field class="full-width">
        <mat-label>Nombre del hospital</mat-label>
        <input matInput type="text" formControlName="hospitalID" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Provincia</mat-label>
        <mat-select formControlName="province">
          <mat-optgroup
            *ngFor="let comunidad of comunidades"
            [label]="comunidad.comunidad"
          >
            <mat-option
              [value]="province"
              *ngFor="let province of comunidad.provincias"
            >
              {{ province }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Ubicación</mat-label>
        <input matInput type="text" formControlName="location" />
      </mat-form-field>
      <h4>Recursos UCI</h4>
      <mat-form-field class="full-width">
        <mat-label>Camas de UCI disponibles</mat-label>
        <input matInput type="text" formControlName="available" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Camas de UCI totales</mat-label>
        <input matInput type="text" formControlName="total" />
      </mat-form-field>
      <h4>Contacto</h4>
      <mat-form-field class="full-width">
        <mat-label>Nombre</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>E-mail</mat-label>
        <input matInput type="text" formControlName="contact" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Teléfono</mat-label>
        <input matInput type="text" formControlName="phone" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Otra información</mat-label>
        <textarea
          cdkTextareaAutosize
          matInput
          placeholder="Disponibilidad, contacto alternativo, etc"
          type="text"
          formControlName="comments"
        ></textarea>
      </mat-form-field>
      <div class="disclaimer" [textContent]="disclaimerText$ | async"></div>
      <button
        class="full-width"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Guardar
      </button>
      <button
        class="full-width"
        mat-raised-button
        color="warn"
        type="cancel"
        [routerLink]="['/beds']"
        style="margin-top: 20px;"
      >
        Cancelar
      </button>
    </form>
  </mat-card-content>
</mat-card>

<ng-template #loading>
  Loading...
</ng-template>
