import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { BedService } from './services/bed.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

import { version as VERSION } from '../../package.json';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from './components/about/about.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loginUrl = environment.api.login;
  supportLink = environment.support;
  user$ = this._auth.currentUser$;

  public readonly version = VERSION;

  constructor(
    private _auth: AuthService,
    private _bedService: BedService,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog
  ) {}

  logout() {
    this._auth.logout();
  }

  refresh() {
    this._bedService.refresh();
    this._bedService.hospitals$.pipe(take(1)).subscribe((_) => {
      this._snackbar.open(`Actualizado`, null, { duration: 500 });
    });
  }

  showAbout() {
    this._dialog.open(AboutComponent, {
      width: '330px',
      panelClass: 'custom-dialog',
    });
  }
}
