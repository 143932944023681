// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    url: 'https://api.ucitest.telespot.org/dev/',
    login: 'https://auth.ucitest.telespot.org/login?client_id=55ttp87abmeo3rpebf3a20e14c&response_type=token&scope=email+openid&redirect_uri=https://ucitest.telespot.org',
  },
  support:
    'https://docs.google.com/forms/d/e/1FAIpQLSdZVGx_tKFXd2P_v9e10mVt7Lf1TGjcAWeQbLeIUX1XORFiTQ/viewform',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
