import { Component, OnInit, ViewChild } from '@angular/core';
import { BedService } from 'src/app/services/bed.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { IContact } from '../../data/contact';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { IHospitalData } from '../../data/hospital-data';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-beds',
  templateUrl: './beds.component.html',
  styleUrls: ['./beds.component.scss'],
})
export class BedsComponent implements OnInit {
  constructor(
    private _bedsService: BedService,
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this._bedsService.hospitals$.subscribe(
      (hospitals) =>
        (this.dataSource.data = hospitals.sort(
          (h1, h2) => Number(h2.editable) - Number(h1.editable)
        ))
    );
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentUser$ = this._auth.currentUser$;

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.refresh();
  }

  displayedColumns: string[] = [
    'actions',
    // 'map',
    'hospital',
    'available',
    'total_beds',
    'contact',
    'province',
    // 'map',
  ];
  dataSource: MatTableDataSource<IHospitalData> = new MatTableDataSource<
    IHospitalData
  >([]);

  getContactLink(contact: string): string {
    return contact?.includes('@')
      ? `mailto:${contact}`
      : /[0-9]+/.test(contact)
      ? `tel:${contact}`
      : undefined;
  }

  refresh() {
    this._bedsService.refresh();
    this._bedsService.hospitals$.pipe(take(1)).subscribe((_) => {
      this._snackBar.open(`Actualizado`, null, { duration: 800 });
    });
  }

  openContactDialog(contact: IHospitalData) {
    // this._dialog.open(ContactDialogComponent, {
    //   data: {
    //     username: contact.name,
    //     phone: contact.phone,
    //     email: contact.email,
    //   },
    // });
    this._bottomSheet.open(ContactDialogComponent, {
      data: {
        hospitalName: contact.hospital.name,
        updated: contact.updated,
        username: contact.contact.name,
        phone: contact.contact.phone,
        email: contact.contact.email,
        other: contact.contact.comments,
      },
    });
  }

  sortingDataAccessor(
    data: IHospitalData,
    sortHeaderId: string
  ): string | number {
    switch (sortHeaderId) {
      case 'hospital':
        return data.hospital.name;
      case 'available':
        return data.beds.available;
      case 'total_beds':
        return data.beds.available_3d;
      case 'province':
        return data.hospital?.location?.province;
      default:
        return 0;
    }
  }
}
